<template>
    <div class="login-box" style="background-color: #090f55;">
        <div class="login-body">
            <div class="login-left">
                <div class="login-left-top">
                    <div class="login-logo" :style='"background: url("+$loadImg("bg.png",true)+") no-repeat center"'></div>
                </div>
                <div class="login-left-bottom">
                    成语大闯关
                </div>
            </div>
            <div class="login-right">
                <h4 class="login-title">
                    欢迎登陆<br>成语大闯关管理系统 
                </h4>
				<div style="margin-top: 100px;">
					<el-form ref="loginForm" autocomplete="on" :model="loginForm" label-position="left">
					    <el-form-item prop="username" style="margin-bottom:50px;">
					        <el-input type="text" tabindex="1" ref="username"
					            name="username" autocomplete="on" prefix-icon="el-icon-user"
					            placeholder="请输入账户名称"  v-model="loginForm.username"/>
					    </el-form-item>
					 
					    <el-form-item prop="password" style="margin-bottom:50px;">
					        <el-input tabindex="2"  show-password ref="password" name="password" autocomplete="on"
					            placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="loginForm.password" 
								@keyup.enter.native="handleLogin"/>
					    </el-form-item>
					 
					    <el-button type="primary"  @click="handleLogin">
					        <i class="el-icon-s-promotion"/>登 录
					    </el-button>
					</el-form>
				</div>
            </div>
        </div>
		<div class="icp">
			<a href="https://beian.miit.gov.cn/" target="_blank">© 2023-2024 sanhequalcomm.com 版权所有 鲁ICP备2023032891号-1</a>
		</div>
    </div>
</template>
 
<script>
export default {
    name: "Login",
    data() {
        return {
			loading: false,
			loginForm: {
				username: "",
				password: ""
			},
        };
    },
    methods: {
		handleLogin() {
			if(!this.loginForm.username){
				return this.$notify.error({message: '请输入账号'});
			}
			if(!this.loginForm.password){
				return this.$notify.error({message: '请输入密码'});
			}
			
			this.$post('sys-admin-login',this.loginForm).then(res=>{
				if( res.extra.UserCheck ){
					//设置登陆信息
					this.$apimit.set(res.extra.UserCheck) //用户id
					this.$vuex('AdminInfo',res.data,true);
					this.$vuex('UserCheck',res.extra.UserCheck,true);
					this.$router.replace("/app/AdvertStatistics");
				}else{
					this.loading = false,this.$notify.error({message: '请重新登陆'});
				}
			}).catch(err=>{
				this.loading = false,this.$notify.error({message: err});
			})
		}
    },
    
};
</script>
 
<style lang="scss">

.icp{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	text-align: center;
	color: #ffffff;
}
 
.login-box{
    width: 100%;
    height: 100%;
    display: flex;
    background-size: cover;
 
    .login-body{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .login-left{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px 0 0 10px;
            width: 440px;
            height: 62vh;
            background: #000;
            background-color: rgba(0,0,0,.6);
            padding: 50px 60px;
            .login-left-top{
                display: flex;
                color: #fff;
                align-items: center;
                margin: 100px auto;
                .login-logo{
                    width: 300px;
                    height: 300px;
                    // 系统的Logo
                    // background-image: url(./logo.png);
                    background-size: cover;
                }
            }
            .login-left-bottom{
                font-size: 14px;
                color: #999;
                text-align: center;
            }
        }
        .login-right{
            border-radius: 0 10px 10px 0;
            height: 62vh;
            background: #fff;
            padding: 50px 60px;
            .login-title{
                color: #2568ff;
                font-size: 24px;
                font-weight: 700;
                line-height: 40px;
                letter-spacing: 5px;
            }
        }
    }
    
    .el-input__inner{
        height: 40px;
        font-size: 16px;
        padding-left: 35px;
    }
}
 
 
@media screen and (max-device-width: 600px) {
    .login-left{
        display: none !important;
    }
    .login-right{
        border-radius:10px !important;
    }
 
}
 
</style>