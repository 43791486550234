<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {
	async created () {
		this.$apimit.set(this.$getter("UserCheck"),{})
		await this.checkLogin();
	},
	methods: {
		checkLogin(){
			if( !this.$apimit.get('Loginid') ){
				this.$router.replace("/");
			}
		},
	},
	watch:{
		'$route.path':function(path){
			this.checkLogin();
		}
	}
};
</script>