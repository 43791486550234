import layout from '../layout.vue';
const _import = (file) => import(`./pages/${file}.vue`)

export default {
    path: '/sys/',
    // redirect: '/sys/',
    component:layout,
    children:[
        {name: '系统-管理员'                  ,path: 'Admin'                        ,component: ()=>_import('Admin')},
        {name: '系统-权限-路由'               ,path: 'PowerRouter'                  ,component: ()=>_import('PowerRouter')},
        {name: '系统-设置'                    ,path: 'Settings'                     ,component: ()=>_import('Settings')},
		{name: '系统-更改密码'                ,path: 'Password'                     ,component: ()=>_import('Password')},
        // add route here
    ]
}