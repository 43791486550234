import lockr  from 'lockr'
import apimithttp from './apimithttp';

var prefix = 'router-cache',RouterData = lockr.get(prefix) || {},RouterDataList = {};


async function router(time){
    return new Promise((resolve, reject) => {
        var _DATA_ = Object.assign({},RouterData);
        if( Object.keys(_DATA_).length === 0 ){
            apimithttp.get('all-router').then(res=>{
                if( typeof time == 'undefined' ){
                    _DATA_ = RouterData = res.data;
				}else if( typeof time == 'boolean' && time ){
					_DATA_ = RouterData = res.data;
					lockr.set(prefix,RouterData);
                }

                resolve(_DATA_);
            }).catch(err=>reject(err));
        }else{
            resolve(_DATA_);
        }
    });
}