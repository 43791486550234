export default {
	// 获取图片
	$img(path,local){
		if( path ){
			if(path.substring(0,4)=='http'){
				return path;
			}else{
				return $config.apihost +'/'+ path;
			}
		}else{
			return '';
		}
	},
}