<template>
    <page-layout :nav-items="[]" :side-items="sideitems" />
</template>
<script>
export default {
    data() {
        return {
            sideitems: [
				{title: '平台统计'                     ,path: 'app/AdvertStatistics'		,icon:'el-icon-s-data'},
				{title: '广告记录'                     ,path: 'app/AdvertRecord'			,icon:'el-icon-video-camera'},
				{title: '成语列表'                     ,path: 'app/IdiomIdiom'			,icon:'el-icon-picture-outline'},
                {title: '用户列表'                     ,path: 'app/UserUser' 			,icon:'el-icon-user'},
				{title: '用户-登录记录'                ,path: 'app/UserLoginRecord'		,icon:'el-icon-position'},
				{title: '充值'                         ,path: 'app/RechargeRecharge'	,icon:'el-icon-goods'},
				{title: '充值-记录'                    ,path: 'app/RechargeRecord'		,icon:'el-icon-s-order'},
				{title: '系统' ,items: [
                    {title: '设置'       			   ,path: 'sys/Settings'},
                    {title: '更改密码'       		   ,path: 'sys/Password'},
                ]},
                // add menu here
            ]
        }
    }
}
</script>