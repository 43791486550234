import layout from '../layout.vue';
const _import = (file) => import(`./pages/${file}.vue`)

export default {
    path: '/app/',
    // redirect: '/app/',
    component:layout,
    children:[
        {name: '用户-广告记录'                ,path: 'AdvertRecord'                       ,component: ()=>_import('AdvertRecord')},
        {name: '用户-成语'                    ,path: 'IdiomIdiom'                        ,component: ()=>_import('IdiomIdiom')},
        {name: '用户'                         ,path: 'UserUser'                         ,component: ()=>_import('UserUser')},
		{name: '充值'                         ,path: 'RechargeRecharge'                     ,component: ()=>_import('RechargeRecharge')},
		{name: '充值-记录'                    ,path: 'RechargeRecord'                       ,component: ()=>_import('RechargeRecord')},
		{name: '用户-登录记录'                ,path: 'UserLoginRecord'                  ,component: ()=>_import('UserLoginRecord')},
		{name: '广告记录'                     ,path: 'AdvertStatistics'                 ,component: ()=>_import('AdvertStatistics')},
        // add route here
    ]
}