import qs     from 'qs'
import axios  from 'axios'
import apimit from './apimit.js';

const apimithttp = {
    $request : axios.create({
        timeout: 50000,
        headers: {
            // "Content-Type": "application/json", // 请求体中的数据会以json字符串的形式发送到后端
            // "Content-Type": "multipart/form-data", // 它会将请求体的数据处理为一条消息，以标签为单元，用分隔符分开。既可以上传键值对，也可以上传文件。
            "Content-Type": "application/x-www-form-urlencoded", // 请求体中的数据会以普通表单形式（键值对）发送到后端
        }
    }),

    request(method,route,data,options){
        return new Promise((resolve, reject) => {
            options = Object.assign(options||{},{
                url     : ($config.apihost || '') + '/api.php?r='+route,
                method  : method,
                headers : apimit.permit(),
            })
            //表单验证
            if( data instanceof Array && data.length>1 ){
                let check = apimit.ruledata(data[0],data[1])
                if( !check[0] ){
                    return reject(check[1]);
                }
                data = data[0];
            }

            for(let key in data){
                if(data[key] == null){
                    delete data[key];
                }
            }

            if( ['put','post','patch'].indexOf(options.method.toLowerCase())!=-1 ){
                options['data'] = qs.stringify(data);
            }else{
                options['params'] = data;
            }

            apimithttp.$request.request(options).then(res=>{
                if( res.status === 200 ){
                    res.data.code==0?resolve(res.data):reject(res.data.msg);
                }else{
                    reject(res.statusText);
                }
            }).catch(err=>{
                reject(err)
            })
        });
    },

    del(route,data,options){
        return apimithttp.request('del',route,data,options);
    },
    get(route,data,options){
        return apimithttp.request('get',route,data,options);
    },
    put(route,data,options){
        return apimithttp.request('put',route,data,options);
    },
    post(route,data,options){
        return apimithttp.request('post',route,data,options);
    },
    save(route,data,key,options){
        return data[key || 'id']?apimithttp.put(route+'/'+data[key || 'id'],data,options):apimithttp.post(route,data,options);
    }
}
export default apimithttp;