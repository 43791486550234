import Vue from 'vue'
import App from './App.vue'
import {store} from '../basic/tools/store.js'
import '../basic';
import {router} from './mainextend'
/* 导入echarts组件 */
import echarts from 'echarts'
Vue.use(echarts);
new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
