import Vue from 'vue'
import ElementUI      from 'element-ui'
Vue.use(ElementUI);

import VueBus from 'vue-bus';
Vue.use(VueBus);


import apimit from './tools/apimit.js';
import cache from './tools/cache.js';
import loafun from './tools/lodfun.js';
// import global from './tools/global.js';
import unipro from './tools/unipro.js';

import apimithttp from './tools/apimithttp.js';

import './index.scss';
import './tools/router.js'
import './components/index.js';

Vue.mixin({
	methods:unipro,
	beforeCreate:function(){
		this.$config    = $config;
		this._       = loafun;
		this.$cache  = cache;
		
		this.$apimit = apimit;
		// apimithttp
		this.$get  = apimithttp.get;
		this.$put  = apimithttp.put;
		this.$del  = apimithttp.del;
		this.$post = apimithttp.post;
		this.$save = apimithttp.save;
		this.$query = (name,defval)=>{
			let query = new Object();
			if (location.href.indexOf("?") != -1) {
				let params = location.href.substr(location.href.indexOf("?")+1).split("&");
				for( let param of params){
					param = param.split("=");
					query[param[0]] = unescape(param[1]);
				}
			}
			return name?(query[name] || defval || null):query;
		}

		this.$loadImg = (path,local)=>{
			if(path){
				if(path.substring(0,4)=='http'){
					return path;
				}else if(local){
					return require('../../static/'+path);
				}else{
					return $config.apihost + '/'+ path;
				}
			}else{
				return ''
			}
		}
	}
})