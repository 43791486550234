import Vue from 'vue'
Vue.component('page-layout', () => import('./layout/layout.vue'))

// ===================element==============
Vue.component('el-select-tags'   ,() => import('./element/selectTags/index.vue'))
Vue.component('el-select-tree'   ,() => import('./element/selectTree/index.vue'))
Vue.component('el-select-trees'  ,() => import('./element/selectTrees/index.vue'))
Vue.component('el-select-search' ,() => import('./element/selectSearch/index.vue'))
Vue.component('el-goodskus-list' ,() => import('./element/goodSkus/skus-list.vue'))
Vue.component('el-goodskus-table',() => import('./element/goodSkus/skus-table.vue'))


// ===================page==================
Vue.component('page', () => import('./pages/page/index.vue'))
Vue.component('page-img', () => import('./pages/pageImg/index.vue'))
Vue.component('page-area', () => import('./pages/pageArea/index.vue'))
Vue.component('page-list', () => import('./pages/pageList/index.vue'))
Vue.component('page-table', () => import('./pages/pageTable/index.vue'))
Vue.component('page-files', () => import('./pages/pageFiles/index.vue'))
Vue.component('page-editor', () => import('./pages/pageEditor/index.vue'))
Vue.component('page-dialog', () => import('./pages/pageDialog/index.vue'))
Vue.component('page-drawer', () => import('./pages/pageDrawer/index.vue'))
// Vue.component('page-video', () => import('./pages/pageVideo/index.vue'))
Vue.component('page-router' ,() => import('./pages/pageRouter/index.vue'))
Vue.component('page-images', () => import('./pages/pageImages/index.vue'))
Vue.component('page-request', () => import('./pages/pageRequest/index.vue'))
Vue.component('page-progress', () => import('./pages/pageProgress/index.vue'))
Vue.component('page-description', () => import('./pages/pageDescription/index.vue'))