import Vue from 'vue'
import lockr from 'lockr'
import Vuex,{mapGetters} from 'vuex'
Vue.use(Vuex)
var prefix = 'vuex-cache',VuexData = lockr.get(prefix,{})|| {},VuexDataTimestamp = lockr.get(prefix+'-timestamp',{}) || {},VuexTesting = 0;
Vue.mixin({
	computed:{
		...mapGetters(['$getter']),
	},
	beforeCreate() {
		this.$vuex = (name, value,cache) => {
			if( value ){
				return this.$store.commit('$vuex', {name:name,data:value,time:cache})
			}else if( name ){
				let _state = this.$store.state;
				for( let _name of name.split('.')){
					if(_state[_name]){
						_state = _state[_name];
					}else{
						return null;
					}
				}
				return _state;
			}else{
				return this.$store.state;
			}
		}
	},
	mounted:function(){
		if( parseInt(VuexTesting) != (Date.parse(new Date())/1000) ){
			for( let name in Object.assign({},VuexData)){
				let timestamp = VuexDataTimestamp[name] || null;
				if( timestamp && parseInt(timestamp) < (Date.parse(new Date()) / 1000) ){
					delete VuexData[name],delete VuexDataTimestamp[name];
					this.$store.commit('$vuex', {name:name,data:null});
				}
			}
			VuexTesting = (Date.parse(new Date())/1000);
		}
	},
});

export const store = new Vuex.Store({
	state: Object.assign({},VuexData),
	getters: {
		$getter:state=> (name,defval) =>{
			let _state = state,nameArr = name.split('.');
			for(let _name of nameArr){
				if( _state[_name] ){
					_state = _state[_name];
				}else{
					return defval || null;
				}
			}
			return _state || null;
		}
	},
	mutations: {
		$vuex(state,paylod){
			if( paylod && paylod.name && typeof(paylod.data)!='undefined'  ){
				let _state = state,nameArr = paylod.name.split('.'),nameLen = nameArr.length;
				for(let index = 0; index < nameLen -1; index ++) {
					if( !_state[nameArr[index]] ){
						_state[nameArr[index]] = {}
					}
					_state = _state[nameArr[index]];
				}
				Vue.set(_state,nameArr[nameLen -1],paylod.data);

				if( paylod.data==null ){
					if( VuexData[nameArr[0]] ){
						delete VuexData[nameArr[0]],delete VuexDataTimestamp[nameArr[0]];
						lockr.set(prefix,VuexData);
						lockr.set(prefix+'-timestamp',VuexDataTimestamp);
					}
				}else if( typeof paylod.time == 'boolean' && paylod.time ){
					VuexData[nameArr[0]] = state[nameArr[0]];
					lockr.set(prefix,VuexData);
				}else if( typeof paylod.time == 'number' ){
					VuexData[nameArr[0]] = state[nameArr[0]];
					VuexDataTimestamp[nameArr[0]] = ( (Date.parse(new Date())/1000) + parseInt(paylod.time) ).toString();
				
					lockr.set(prefix,VuexData);
					lockr.set(prefix+'-timestamp',VuexDataTimestamp);
				}
			}
		}
	}
})