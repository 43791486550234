import Vue       from 'vue'
import Vuex      from 'vuex'
import VueRouter from 'vue-router'



Vue.use(Vuex)
const storeInject = {},storeFiles = require.context('./', true, /\/*.\/store\.js$/)
storeFiles.keys().forEach(key => {
    storeInject[key.replace(/(\.\/|\/store\.js)/g, '')] = storeFiles(key).default
})

export const store =  new Vuex.Store({
	// modules:inject,
    state: {},
    mutations: {},
    actions: {},
    modules: {}
})


Vue.use(VueRouter);
const routerInject = [],routerFiles = require.context('./', true, /\/*.\/router\.js$/)
routerFiles.keys().forEach(key => {
    routerInject.push( routerFiles(key).default );
})

export const router  = new VueRouter({
    routes: [
        ...routerInject,{path: '/',meta:{}, name: '用户登录',component: require('./login.vue').default},
    ],
    scrollBehavior: () => ({y: 0})
})

router.beforeEach((to,from,next)=>{
    // console.log(to)
    next();
})


