import Vue    from 'vue'
import lockr  from 'lockr'


const cache = {
	prefix: 'ql-cache',
	set:function(key ,data ,time){
        lockr.set(key,data);
		var seconds = parseInt(time);
		if (seconds > 0) {
			var timestamp = Date.parse(new Date());
			timestamp = timestamp / 1000 + seconds;
			lockr.set(key + this.prefix, timestamp + "")
		} else {
			lockr.rm(key + this.prefix)
		}
	},
	
	get:function(key ,def){
		var deadtime = parseInt(lockr.get(key + this.prefix))
		if (deadtime) {
			if (parseInt(deadtime) < Date.parse(new Date()) / 1000) {
				if (def) {
					return def;
				} else {
					lockr.rm(key),lockr.rm(key + this.prefix);
					return false;
				}
			}
        }
        return lockr.get(key) || def || false;
	},
	
	rm:function(key){
		lockr.rm(key);
		lockr.rm(key + this.prefix);
	},
	
	clear:function(){
		lockr.flush();
	}
}



Vue.$cache = Vue.prototype.$cache = window.$cache = cache;
export default cache